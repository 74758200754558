import React from 'react';
import Layout from "../components/layout"
import Qualities from "../components/qualities"
import Svg from "../components/svg"
import SEO from "../components/seo"
import A80 from "../animations/a80"
import A160 from "../animations/a160"
import A400 from "../animations/a400"

export default ({ pageContext: { content, pages, lang, texts, langs } }) => {
    const data = content.data;
    return (
        <Layout pages={pages} lang={lang} texts={texts} langs={langs}>
            <SEO description={data.seo_description} title={data.seo_title} image={data.seo_image} />
            <div className="layer-top layer-top-inovations">
                <A160 className="inovations-liquid-1" />
                <A400 className="inovations-liquid-2" />
                <div className="relative">
                    <div className="wrap-full pl-xxl pr-s">
                        <div className="group group-inovations">
                            <div className="left">
                                <h1
                                    data-sal="slide-right"
                                    data-sal-duration="1000"
                                    dangerouslySetInnerHTML={{__html: data.intro_title }} />
                                <div
                                    data-sal="slide-right"
                                    data-sal-duration="1000"
                                    className="h3" dangerouslySetInnerHTML={{__html: data.intro_text }} />
                            </div>
                            <div
                                data-sal="slide-left"
                                data-sal-duration="1000"
                                className="right">
                                <img src={data.intro_image} alt={data.intro_title} title={data.intro_title} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="layer layer-achievements">
                <A80 className="inovations-liquid-3" />
                <div className="small-wrap">
                    <h2
                        data-sal="slide-right"
                        data-sal-duration="1000"
                        className="relative"
                        dangerouslySetInnerHTML={{__html: data.achievements_title }} />
                    <div
                        data-sal="slide-up"
                        data-sal-duration="1000"
                        id="achievements-wrap">

                        <svg className="achievements desktop-hide" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 280.08 1777.34" id="mobile-achievements">
                            <path fill="none" d="M140.079 0c60.31 0 111.71 24.13 131.51 77.89 5.45 14.8 8.49 45.1 8.49 62.11 0 47.55-33.51 81.17-70.01 106.65-22.42 15.66-40.22 33.35-69.99 33.35-28.07 0-69.6-33.46-91.78-47.85C10.109 207.37.079 189.25.079 140c0-38.97 13.82-57.8 48.82-92.8S101.729 0 140.079 0z"/>
                            <path fill="#CAD2F5" d="M140.079 1c-19.18 0-33.05 3.1-46.37 10.35-6.53 3.56-13.06 8.16-20.55 14.47-6.51 5.49-13.77 12.3-23.56 22.08-17.28 17.29-29.57 30.8-37.36 44.7-3.92 6.98-6.69 14.05-8.48 21.61-1.8 7.63-2.68 16.06-2.68 25.79 0 12.91.68 22.92 2.15 31.52 1.53 8.96 3.99 16.57 7.52 23.26 1.79 3.4 3.85 6.56 6.3 9.69 2.4 3.07 5.12 6.03 8.29 9.02 5.94 5.6 13.41 11.27 23.5 17.82 4.19 2.72 9.14 6.17 14.39 9.82 11.18 7.79 25.1 17.48 38.69 24.97 7.42 4.09 13.97 7.12 20.01 9.26 6.8 2.41 12.91 3.64 18.14 3.64 5.86 0 11.38-.68 16.88-2.08 4.93-1.25 9.74-3.07 14.72-5.54 9.33-4.64 17.74-10.86 26.65-17.45 3.61-2.67 7.34-5.43 11.17-8.1 10.03-7 18.61-13.76 26.24-20.65 4.24-3.83 8.2-7.74 11.79-11.61 3.81-4.12 7.32-8.35 10.43-12.56 3.28-4.44 6.21-9.01 8.72-13.57 2.63-4.79 4.87-9.71 6.66-14.64 1.87-5.15 3.3-10.46 4.25-15.79.99-5.55 1.5-11.27 1.5-17.01 0-8.88-.82-20.76-2.19-31.76-.76-6.07-1.65-11.65-2.65-16.6-1.1-5.43-2.31-9.94-3.58-13.41-2.42-6.56-5.37-12.82-8.79-18.62-3.33-5.66-7.19-11.02-11.45-15.92-8.27-9.51-18.32-17.59-29.88-24.04-11.13-6.21-23.74-10.94-37.47-14.07-13.35-3.04-27.82-4.58-42.99-4.58m0-1c60.31 0 111.71 24.13 131.51 77.89 5.45 14.8 8.49 45.1 8.49 62.11 0 47.55-33.51 81.17-70.01 106.65-22.42 15.66-40.22 33.35-69.99 33.35-28.07 0-69.6-33.46-91.78-47.85C10.109 207.37.079 189.25.079 140c0-38.97 13.82-57.8 48.82-92.8S101.729 0 140.079 0z"/>
                            <path fill="none" d="M182.079 140c0-18.09-7.24-31.83-23.37-37.77-4.44-1.64-13.53-4.23-18.63-4.23-14.26 0-24.35.81-32 11.76-4.69 6.73-10 21.31-10 30.24 0 8.42 5.84 20.88 10.15 27.53 7.44 11.46 17.07 14.47 31.85 14.47 11.69 0 19.02-2.46 29.52-12.97s12.48-17.53 12.48-29.03z"/>
                            <path fill="#CAD2F5" d="M181.079 140c0-9.08-1.85-16.8-5.5-22.93-3.82-6.42-9.61-11.1-17.22-13.9-4.69-1.73-13.46-4.17-18.28-4.17-6.53 0-12.84.13-18.19 1.73-5.41 1.62-9.54 4.67-12.98 9.6-4.55 6.51-9.83 20.89-9.83 29.67 0 3.66 1.17 8.52 3.39 14.06 1.79 4.49 4.26 9.32 6.61 12.93 3.53 5.44 7.57 8.98 12.72 11.15 4.68 1.98 10.32 2.86 18.28 2.86 6.26 0 10.69-.74 14.82-2.47 4.51-1.89 8.82-5.03 13.99-10.2 5.1-5.1 8.17-9.36 9.96-13.81 1.58-3.95 2.23-8.16 2.23-14.52m1 0c0 11.51-1.98 18.53-12.48 29.03S151.769 182 140.079 182c-14.78 0-24.41-3.01-31.84-14.47-4.32-6.65-10.16-19.11-10.16-27.53 0-8.93 5.31-23.51 10-30.24 7.65-10.95 17.73-11.76 32-11.76 5.1 0 14.19 2.59 18.63 4.23 16.13 5.94 23.37 19.68 23.37 37.77z"/>
                            <path fill="none" d="M140.079 231c-39.2 0-72.61-15.69-85.48-50.63-3.55-9.62-5.52-29.31-5.52-40.37 0-30.91 21.78-52.76 45.5-69.32 14.58-10.18 26.15-21.68 45.5-21.68 18.24 0 45.24 21.75 59.66 31.1 24.82 16.11 31.34 27.89 31.34 59.9 0 25.33-8.98 37.57-31.73 60.32S165.009 231 140.079 231z"/>
                            <path fill="#CAD2F5" d="M140.079 230c12.41 0 21.37-2 29.97-6.69 8.94-4.87 17.57-12.68 28.59-23.7 11.22-11.22 19.18-19.98 24.23-28.98 2.53-4.51 4.32-9.07 5.47-13.96 1.17-4.92 1.74-10.37 1.74-16.67 0-8.37-.44-14.86-1.39-20.43-.99-5.79-2.58-10.7-4.86-15.01-2.26-4.28-5.34-8.23-9.41-12.07-3.85-3.63-8.69-7.3-15.23-11.54-2.73-1.77-5.95-4.01-9.36-6.39-7.26-5.06-16.3-11.35-25.12-16.21-4.81-2.65-9.04-4.61-12.96-6-4.38-1.56-8.31-2.35-11.67-2.35-3.78 0-7.34.44-10.88 1.34-3.18.81-6.29 1.98-9.5 3.58-6.04 3-11.49 7.04-17.27 11.31-2.35 1.74-4.77 3.53-7.27 5.27-13.85 9.67-24.12 19.17-31.42 29.06-4.34 5.89-7.69 12.03-9.95 18.25-2.46 6.79-3.71 13.92-3.71 21.19 0 10.57 1.9 30.37 5.45 40.03 3.09 8.4 7.5 15.91 13.09 22.34 5.35 6.15 11.85 11.38 19.33 15.55 7.2 4.02 15.37 7.08 24.27 9.11 8.65 1.97 18.02 2.97 27.86 2.97m0 1c-39.2 0-72.61-15.69-85.48-50.63-3.55-9.62-5.52-29.31-5.52-40.37 0-30.91 21.78-52.76 45.5-69.32 14.58-10.18 26.15-21.68 45.5-21.68 18.24 0 45.24 21.75 59.66 31.1 24.82 16.11 31.34 27.89 31.34 59.9 0 25.33-8.98 37.57-31.73 60.32S165.009 231 140.079 231z"/>
                            <path className="mob-path-line mob-path-line-3" fill="none" stroke="#5A69F5" d="M20.08 1776.84V208"/>
                            <circle className="path-dot" fill="#5A69F5" cx="20.08" cy="208" r="4"/>
                            <path className="mob-path-line mob-path-line-2" fill="none" stroke="#5A69F5" d="M10.08 1776.84V140h40"/>
                            <circle className="path-dot" fill="#5A69F5" cx="50.08" cy="140" r="4"/>
                            <path className="mob-path-line mob-path-line-1" fill="none" stroke="#5A69F5" d="M187.08 72H.5v1704.84h9.58"/>
                            <path className="path-dot" fill="#5A69F5" d="M187.08 68c2.21 0 4 1.79 4 4s-1.79 4-4 4-4-1.79-4-4 1.79-4 4-4z"/>
                        </svg>




                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 681 430" className="achievements mobile-hide">
                        <g id="large" transform="translate(440 1601)">
                        	<path fill="none" d="M-22.5-1601c86.16,0,159.59,34.48,187.88,111.27c7.79,21.14,12.12,64.43,12.12,88.73
                        		c0,67.93-47.87,115.95-100.01,152.36C45.45-1226.27,20.03-1201-22.5-1201c-40.1,0-99.44-47.8-131.11-68.36
                        		c-54.56-35.41-68.89-61.28-68.89-131.64c0-55.67,19.74-82.57,69.74-132.57S-77.29-1601-22.5-1601z"/>
                        	<path fill="#CAD2F5" d="M-22.5-1600c-13.83,0-25.67,1.14-36.22,3.48c-10.65,2.37-20.54,6.08-30.23,11.36
                        		c-4.78,2.61-9.53,5.58-14.49,9.09c-4.77,3.38-9.66,7.18-14.93,11.63c-9.3,7.85-19.69,17.58-33.68,31.57
                        		c-24.72,24.72-42.29,44.05-53.46,63.94c-5.61,10.01-9.59,20.14-12.15,30.98c-2.58,10.93-3.84,23.01-3.84,36.94
                        		c0,18.46,0.98,32.8,3.08,45.11c1.11,6.49,2.51,12.32,4.29,17.82c1.79,5.56,3.92,10.64,6.51,15.53c2.57,4.87,5.53,9.42,9.03,13.9
                        		c3.45,4.41,7.34,8.64,11.89,12.94c8.51,8.02,19.2,16.13,33.64,25.5c5.98,3.88,13.05,8.8,20.54,14.02
                        		c8.05,5.61,17.17,11.96,26.53,18.12c10.71,7.06,20.13,12.8,28.78,17.57c10.62,5.85,19.99,10.19,28.65,13.26
                        		c4.75,1.69,9.3,2.98,13.51,3.84c4.46,0.91,8.68,1.38,12.55,1.38c8.41,0,16.33-0.97,24.22-2.98c7.07-1.8,13.98-4.4,21.12-7.95
                        		c13.37-6.65,25.4-15.54,38.13-24.96c5.15-3.81,10.48-7.75,15.95-11.57c14.34-10.01,26.62-19.67,37.53-29.53
                        		c6.06-5.48,11.74-11.07,16.87-16.62c5.46-5.9,10.48-11.95,14.93-17.98c4.7-6.36,8.9-12.9,12.48-19.43
                        		c3.77-6.86,6.98-13.91,9.54-20.97c2.68-7.38,4.73-14.99,6.09-22.62c1.42-7.95,2.14-16.15,2.14-24.38c0-12.71-1.17-29.69-3.13-45.43
                        		c-1.08-8.68-2.36-16.67-3.79-23.74c-1.58-7.77-3.3-14.24-5.14-19.22c-3.46-9.39-7.69-18.36-12.58-26.67
                        		c-4.78-8.11-10.3-15.78-16.41-22.8c-5.97-6.87-12.65-13.27-19.86-19.05c-7.05-5.65-14.77-10.83-22.94-15.39
                        		c-15.93-8.89-33.98-15.67-53.65-20.15C19.9-1597.79-0.79-1600-22.5-1600 M-22.5-1601c86.16,0,159.59,34.48,187.88,111.27
                        		c7.79,21.14,12.12,64.43,12.12,88.73c0,67.93-47.87,115.95-100.01,152.36C45.45-1226.27,20.03-1201-22.5-1201
                        		c-40.1,0-99.44-47.8-131.11-68.36c-54.55-35.41-68.89-61.28-68.89-131.64c0-55.67,19.74-82.57,69.74-132.57
                        		C-102.76-1583.57-77.29-1601-22.5-1601z"/>
                        </g>
                        <g id="small" transform="translate(580 1861) rotate(-90)">
                        	<path fill="none" d="M1661-102.5c25.85,0,45.48-10.34,53.96-33.38c2.34-6.34,6.04-19.33,6.04-26.62c0-20.38-1.16-34.79-16.8-45.71
                        		c-9.61-6.71-30.44-14.29-43.2-14.29c-12.03,0-29.83,8.34-39.33,14.51c-16.37,10.62-20.67,24.38-20.67,45.49
                        		c0,16.7,3.52,27.17,18.52,42.17S1644.56-102.5,1661-102.5z"/>
                        	<path fill="#CAD2F5" d="M1661-103.5c13.05,0,24.15-2.66,32.98-7.91c9.26-5.5,16.01-13.85,20.04-24.81
                        		c2.48-6.73,5.98-19.34,5.98-26.27c0-4.61-0.05-9.51-0.42-14.12c-0.38-4.66-1.05-8.58-2.07-11.99c-1.14-3.83-2.75-7.14-4.9-10.13
                        		c-2.32-3.21-5.25-6.04-8.99-8.65c-9.35-6.53-30-14.11-42.62-14.11c-5.28,0-12.28,1.68-20.24,4.87c-6.43,2.57-13.37,6.12-18.55,9.48
                        		c-4,2.6-7.25,5.36-9.92,8.44c-2.6,2.99-4.62,6.24-6.17,9.93c-1.48,3.52-2.53,7.42-3.2,11.92c-0.62,4.14-0.92,8.84-0.92,14.37
                        		c0,9,1.06,15.38,3.56,21.34c1.36,3.25,3.18,6.42,5.55,9.68c2.39,3.29,5.38,6.7,9.12,10.45c7.33,7.33,13.46,11.75,19.87,14.32
                        		C1645.79-104.43,1651.85-103.5,1661-103.5 M1661-102.5c-16.44,0-26.48-2.83-41.48-17.83S1601-145.8,1601-162.5
                        		c0-21.11,4.3-34.87,20.67-45.49c9.5-6.17,27.3-14.51,39.33-14.51c12.76,0,33.59,7.58,43.2,14.29c15.64,10.92,16.8,25.33,16.8,45.71
                        		c0,7.29-3.7,20.28-6.04,26.62C1706.48-112.84,1686.85-102.5,1661-102.5z"/>
                        </g>
                        <g id="mid" transform="translate(510 1671)">
                        	<path fill="none" d="M-92.5-1341c-56,0-103.73-22.41-122.12-72.32c-5.06-13.74-7.88-41.88-7.88-57.68
                        		c0-44.16,31.11-75.37,65.01-99.03c20.82-14.54,37.35-30.97,64.99-30.97c26.06,0,64.63,31.07,85.22,44.43
                        		c35.46,23.02,44.78,39.83,44.78,85.57c0,36.19-12.83,53.67-45.33,86.17S-56.89-1341-92.5-1341z"/>
                        	<path fill="#CAD2F5" d="M-92.5-1342c17.8,0,30.67-2.87,43.02-9.6c6.06-3.3,12.12-7.57,19.07-13.43
                        		c6.04-5.09,12.78-11.42,21.87-20.5c16.05-16.05,27.45-28.59,34.69-41.49c3.63-6.48,6.21-13.03,7.86-20.05
                        		c1.67-7.08,2.49-14.9,2.49-23.93c0-11.98-0.63-21.28-2-29.26c-1.42-8.31-3.7-15.37-6.98-21.58c-1.66-3.15-3.57-6.09-5.84-8.99
                        		c-2.23-2.85-4.75-5.59-7.69-8.37c-5.51-5.2-12.45-10.46-21.81-16.54c-3.89-2.52-8.49-5.73-13.36-9.12
                        		c-10.38-7.23-23.31-16.23-35.92-23.18c-6.89-3.79-12.96-6.61-18.57-8.6c-6.31-2.24-11.97-3.37-16.82-3.37
                        		c-5.44,0-10.56,0.63-15.65,1.93c-4.57,1.16-9.04,2.84-13.66,5.14c-8.66,4.31-16.47,10.08-24.73,16.19
                        		c-3.35,2.48-6.81,5.04-10.37,7.53c-9.31,6.5-17.28,12.77-24.36,19.17c-8.1,7.32-15.04,14.87-20.62,22.44
                        		c-3.04,4.12-5.76,8.36-8.09,12.59c-2.44,4.44-4.52,9.01-6.18,13.58c-1.73,4.78-3.06,9.71-3.94,14.65
                        		c-0.92,5.15-1.39,10.46-1.39,15.79c0,8.25,0.76,19.27,2.03,29.48c0.7,5.64,1.53,10.82,2.46,15.41c1.02,5.04,2.14,9.22,3.33,12.44
                        		c2.24,6.08,4.98,11.9,8.15,17.28c3.09,5.25,6.67,10.22,10.63,14.77c7.67,8.82,17,16.32,27.72,22.3
                        		c10.33,5.76,22.03,10.15,34.78,13.06C-120.01-1343.43-106.59-1342-92.5-1342 M-92.5-1341c-56,0-103.73-22.41-122.12-72.32
                        		c-5.06-13.74-7.88-41.88-7.88-57.68c0-44.15,31.11-75.37,65.01-99.03c20.82-14.54,37.35-30.97,64.99-30.97
                        		c26.06,0,64.63,31.07,85.22,44.43c35.46,23.01,44.78,39.83,44.78,85.57c0,36.19-12.83,53.67-45.33,86.17S-56.89-1341-92.5-1341z"/>
                        </g>
                        <path className="path-line path-line-1" id="line" fill="none" stroke="#5A69F5" d="M0.5,430V232h219"/>
                        <path className="path-line path-line-2" id="line-2" fill="none" stroke="#5A69F5" d="M680.5,430V153h-224"/>
                        <path className="path-line path-line-3" id="line-3" fill="none" stroke="#5A69F5" d="M340.5,311v119"/>
                        <path className="path-dot" id="dot" fill="#5A69F5" d="M456.5,149c2.21,0,4,1.79,4,4s-1.79,4-4,4s-4-1.79-4-4S454.29,149,456.5,149z"/>
                        <circle className="path-dot" id="dot-2" fill="#5A69F5" cx="340.5" cy="311" r="4"/>
                        <circle className="path-dot" id="dot-3" fill="#5A69F5" cx="219.5" cy="232" r="4"/>
                        </svg>

                    </div>
                    <div className="achievement-blocks">
                        <div
                            data-sal="slide-up"
                            data-sal-duration="800">
                            <h3 dangerouslySetInnerHTML={{__html: data.achievements_block_1_title }} />
                            <p dangerouslySetInnerHTML={{__html: data.achievements_block_1_text }} />
                        </div>
                        <div
                            data-sal-delay="400"
                            data-sal="slide-up"
                            data-sal-duration="800">
                            <h3 dangerouslySetInnerHTML={{__html: data.achievements_block_2_title }} />
                            <p dangerouslySetInnerHTML={{__html: data.achievements_block_2_text }} />
                        </div>
                        <div
                            data-sal-delay="800"
                            data-sal="slide-up"
                            data-sal-duration="800">
                            <h3 dangerouslySetInnerHTML={{__html: data.achievements_block_3_title }} />
                            <p dangerouslySetInnerHTML={{__html: data.achievements_block_3_text }} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="layer layer-important">
                <A80 className="inovations-liquid-4"  />
                <A400 className="inovations-liquid-5" />
                <div className="small-wrap">
                    <h2
                        data-sal="slide-left"
                        data-sal-duration="1000"
                        dangerouslySetInnerHTML={{__html: data.important_title }} />
                </div>
                <div className="important-bottom">
                    <div className="wrap-full">
                        <div>
                            <div
                                data-sal="slide-left"
                                data-sal-duration="800"
                                className="no">1<Svg name="circle" /></div>
                            <div
                                data-sal-delay="200"
                                data-sal="slide-up"
                                data-sal-duration="800"
                                dangerouslySetInnerHTML={{__html: data.important_1 }} />
                        </div>
                        <div>
                            <div
                                data-sal-delay="400"
                                data-sal="slide-left"
                                data-sal-duration="800"
                                className="no">2<Svg name="circle" /></div>
                            <div
                                data-sal-delay="600"
                                data-sal="slide-up"
                                data-sal-duration="600"
                                dangerouslySetInnerHTML={{__html: data.important_2 }} />
                        </div>
                        <div>
                            <div
                                data-sal-delay="800"
                                data-sal="slide-left"
                                data-sal-duration="800"
                                className="no">3<Svg name="circle" /></div>
                            <div
                                data-sal-delay="1000"
                                data-sal="slide-up"
                                data-sal-duration="800"
                                dangerouslySetInnerHTML={{__html: data.important_3 }} />
                        </div>
                    </div>
                </div>
            </div>
            <Qualities data={texts} from="inovations" />
        </Layout>
    )
}
