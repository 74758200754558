import React from 'react';
import A160 from '../animations/a160';
import A400 from '../animations/a400';

const Qualities = ({ data, from }) => (
  <div className='layer layer-quality'>
    <div className='wrap-full pl-xl pr-xl'>
      {/* <div
        data-sal='fade'
        data-sal-duration='1000'
        className='layer-label'
        dangerouslySetInnerHTML={{ __html: data.quality_section }}
      /> */}
      <h2
        data-sal='slide-right'
        data-sal-duration='1000'
        dangerouslySetInnerHTML={{ __html: data.quality_title }}
      />
      <div className='qualities'>
        {data.quality_block_1_img && (
          <div data-sal='slide-up' data-sal-duration='600'>
            <h3
              dangerouslySetInnerHTML={{ __html: data.quality_block_1_title }}
            />
            {/* <p dangerouslySetInnerHTML={{__html: data.quality_block_1_text }} /> */}
            {data.quality_block_1_url ? (
              <a target='_blank' href={data.quality_block_1_url} download>
                <img
                  src={data.quality_block_1_img}
                  alt={data.quality_block_1_title}
                  title={data.quality_block_1_title}
                />
              </a>
            ) : (
              <img
                src={data.quality_block_1_img}
                alt={data.quality_block_1_title}
                title={data.quality_block_1_title}
              />
            )}
          </div>
        )}
        {data.quality_block_2_img && (
          <div data-sal='slide-up' data-sal-delay='200' data-sal-duration='600'>
            <h3
              dangerouslySetInnerHTML={{ __html: data.quality_block_2_title }}
            />
            {/* <p dangerouslySetInnerHTML={{__html: data.quality_block_2_text }} /> */}
            {data.quality_block_2_url ? (
              <a target='_blank' href={data.quality_block_2_url}>
                <img
                  src={data.quality_block_2_img}
                  alt={data.quality_block_2_title}
                  title={data.quality_block_2_title}
                />
              </a>
            ) : (
              <img
                src={data.quality_block_2_img}
                alt={data.quality_block_2_title}
                title={data.quality_block_2_title}
              />
            )}
          </div>
        )}
        {data.quality_block_3_img && (
          <div data-sal='slide-up' data-sal-delay='400' data-sal-duration='600'>
            <h3
              dangerouslySetInnerHTML={{ __html: data.quality_block_3_title }}
            />
            {/* <p dangerouslySetInnerHTML={{__html: data.quality_block_3_text }} /> */}
            {data.quality_block_3_url ? (
              <a target='_blank' href={data.quality_block_3_url}>
                <img
                  src={data.quality_block_3_img}
                  alt={data.quality_block_3_title}
                  title={data.quality_block_3_title}
                />
              </a>
            ) : (
              <img
                src={data.quality_block_3_img}
                alt={data.quality_block_3_title}
                title={data.quality_block_3_title}
              />
            )}
          </div>
        )}
        {data.quality_block_4_img && (
          <div data-sal='slide-up' data-sal-delay='600' data-sal-duration='600'>
            <h3
              dangerouslySetInnerHTML={{ __html: data.quality_block_4_title }}
            />
            {/* <p dangerouslySetInnerHTML={{__html: data.quality_block_4_text }} /> */}
            {data.quality_block_4_url ? (
              <a target='_blank' href={data.quality_block_4_url}>
                <img
                  src={data.quality_block_4_img}
                  alt={data.quality_block_4_title}
                  title={data.quality_block_4_title}
                />
              </a>
            ) : (
              <img
                src={data.quality_block_4_img}
                alt={data.quality_block_4_title}
                title={data.quality_block_4_title}
              />
            )}
          </div>
        )}
        {data.quality_block_5_img && (
          <div data-sal='slide-up' data-sal-delay='800' data-sal-duration='600'>
            <h3
              dangerouslySetInnerHTML={{ __html: data.quality_block_5_title }}
            />
            {/* <p dangerouslySetInnerHTML={{__html: data.quality_block_5_text }} /> */}
            {data.quality_block_5_url ? (
              <a target='_blank' href={data.quality_block_5_url}>
                <img
                  src={data.quality_block_5_img}
                  alt={data.quality_block_5_title}
                  title={data.quality_block_5_title}
                />
              </a>
            ) : (
              <img
                src={data.quality_block_5_img}
                alt={data.quality_block_5_title}
                title={data.quality_block_5_title}
              />
            )}
          </div>
        )}
        {data.quality_block_6_img && (
          <div
            data-sal='slide-up'
            data-sal-delay='1000'
            data-sal-duration='600'
          >
            <h3
              dangerouslySetInnerHTML={{ __html: data.quality_block_6_title }}
            />
            {/* <p dangerouslySetInnerHTML={{__html: data.quality_block_6_text }} /> */}
            {data.quality_block_6_url ? (
              <a target='_blank' href={data.quality_block_6_url}>
                <img
                  src={data.quality_block_6_img}
                  alt={data.quality_block_6_title}
                  title={data.quality_block_6_title}
                />
              </a>
            ) : (
              <img
                src={data.quality_block_6_img}
                alt={data.quality_block_6_title}
                title={data.quality_block_6_title}
              />
            )}
          </div>
        )}
      </div>
    </div>
  </div>
);

export default Qualities;
